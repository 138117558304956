body {
  background-color: #141414;
  color: #fff;
  font-family: a-otf-ud-shin-go-pr6n, sans-serif;
  font-weight: 300;
  font-style: normal;
}

h1 {
  margin: 0px;
  font-family: a-otf-midashi-go-mb31-pr6n, sans-serif;
  font-weight: 600;
  font-style: normal;
}

#wrapper {
  width: 1100px;
  margin: auto;
}

#appOuter {
  border: 5px solid #111;
  border-radius: 10px;
  padding-top: 56.25%;
  position: relative;
  margin: 25px 0px;
}

#app {
  position: absolute;
  border-radius: 10px;
  background-color: #222;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#caption {
  text-align: center;
  font-size: 14px;
}

a {
  color: #fff;
}

.wf-loading {
  visibility: hidden;
}